"use strict";

class ClientRev2Obj {
    constructor() {
        this.element = null;
        this.column_count = 0;
        this.row_count = 0;
        this.clients_loop = 0;
        this.logo_places = [];
        this.grid_locations = [];
        this.logo_place_index = 0;
    }
}

let clients_rev2_objects = [];

function getCSSVariable(object, variableName) {
    return window.getComputedStyle(object).getPropertyValue(variableName);
}

function initializeMultipleClientsGrid() {
    const clients_rev2 = document.querySelector('.clients_rev2');
    if(!clients_rev2) {
        return;
    }
    const client_grids = clients_rev2.querySelectorAll('.clients_rev2-grid');
    client_grids.forEach(
        (grid, gridIndex) => {
            clients_rev2_objects.push(new ClientRev2Obj());
            clients_rev2_objects[gridIndex].element = grid;
            clients_rev2_objects[gridIndex].column_count = Number(getCSSVariable(grid, '--clients-column-count'));
            clients_rev2_objects[gridIndex].row_count = Number(getCSSVariable(grid, '--clients-row-count'));

            //begin: initialize logo_places
            let tempArray = [];
            for(let i = 0; i < clients_rev2_objects[gridIndex].column_count * clients_rev2_objects[gridIndex].row_count; i++) {
                tempArray.push(i);
            }
            clients_rev2_objects[gridIndex].logo_places = tempArray.sort(() => Math.random() - 0.5);
            //end: initialize logo_places

            // begin: initialize logo_places
            const grid_gallery_items = clients_rev2_objects[gridIndex].element.querySelectorAll('li');
            grid_gallery_items.forEach(
                (gallery_item, itemIndex) => {
                    const gallery_item_display = window.getComputedStyle(gallery_item).display;
                    switch(gallery_item_display) {
                        case 'flex':
                            gallery_item.classList.add('clients_rev2--show');
                            const col_start = (itemIndex % clients_rev2_objects[gridIndex].column_count) + 1;
                            const row_start = Math.ceil( (itemIndex + 1) / clients_rev2_objects[gridIndex].column_count );
                            clients_rev2_objects[gridIndex].grid_locations.push({'column': col_start, 'row': row_start});
                            gallery_item.dataset.column = col_start;
                            gallery_item.dataset.row = row_start;
                            gallery_item.style = `grid-column: ${col_start}/span 1; grid-row: ${row_start}/span 1`;
                            break;
                        case 'none':
                            gallery_item.classList.add('clients_rev2--hide');
                            gallery_item.dataset.clientsLoop = '0';
                            break;
                    }
                }
            );
            // end: initialize logo_places
        }
    );//end forEach

    //begin: initialize methods
    clients_rev2_objects.forEach(
        (clients_rev2_obj) => {
            // selectNextLogo
            clients_rev2_obj.selectNextLogo = function() {
                let nextLogo = clients_rev2_obj.element.querySelector(`.clients_rev2--hide[data-clients-loop="${clients_rev2_obj.clients_loop}"]`);
                if(nextLogo === null && clients_rev2_obj.clients_loop < 1000) {
                    clients_rev2_obj.clients_loop++;
                    return clients_rev2_obj.selectNextLogo();
                }
                return nextLogo;
            };// end selectNextLogo

            // selectNextLogoPlace
            clients_rev2_obj.selectNextLogoPlace = function() {
                let nextPlace = clients_rev2_obj.logo_places[clients_rev2_obj.logo_place_index];
                clients_rev2_obj.logo_place_index = (clients_rev2_obj.logo_place_index + 1) % clients_rev2_obj.logo_places.length;
                return nextPlace;
            };//end selectNextLogoPlace

            // changeLogo
            clients_rev2_obj.changeLogo = function(logo_place, nextLogo) {
                const nextGridLocation = clients_rev2_obj.grid_locations[logo_place];
                // console.log(nextGridLocation, nextLogo);
                const previousLogo = clients_rev2_obj.element.querySelector(`[data-column="${nextGridLocation.column}"][data-row="${nextGridLocation.row}"]`);
                // hide previous Logo 1
                previousLogo.dataset.clientsLoop = clients_rev2_obj.clients_loop + 1;
                previousLogo.style.zIndex = '2';
                previousLogo.style.transition = 'opacity linear .3s';
                previousLogo.style.opacity = '0';

                // show next logo
                nextLogo.style = `grid-column: ${nextGridLocation.column}/span 1; grid-row: ${nextGridLocation.row}/span 1;`;
                nextLogo.style.display = 'flex';
                nextLogo.style.zIndex = '1';
                nextLogo.dataset.column = nextGridLocation.column;
                nextLogo.dataset.row = nextGridLocation.row;
                nextLogo.classList.remove('clients_rev2--hide');
                nextLogo.classList.add('clients_rev2--show');

                // hide previous logo 2
                setTimeout(function(){
                    previousLogo.style = 'display: none;'; //grid ozelliklerini de sifirla
                    delete previousLogo.dataset.column;
                    delete previousLogo.dataset.row;
                    previousLogo.classList.remove('clients_rev2--show');
                    previousLogo.classList.add('clients_rev2--hide');
                }, 350);
            };
        }
    );//end forEach
    //end: initialize methods
}

function clearIntervalLogoChange() {
    window.clearInterval(window.intervalIdLogoChange);
}

function setIntervalLogoChange() {
    window.intervalIdLogoChange = setInterval(function(){
        clients_rev2_objects.forEach(
            clients_rev2_obj => {
                clients_rev2_obj.changeLogo(
                    clients_rev2_obj.selectNextLogoPlace(),
                    clients_rev2_obj.selectNextLogo()
                );
            }
        );
    }, 2000);
}

initializeMultipleClientsGrid();
setIntervalLogoChange();